import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { SSOsService } from "../ssos.service";
import { SsoConfig } from "../../../../models/shared";
import { TitleService } from "../../../../services/title.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-sso-form",
    templateUrl: "./sso-form.component.html"
})
export class SSOFormComponent implements OnInit {
    sso = new SsoConfig();
    private ssoId: string;
    private action: string;
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sks = inject(SSOsService);
    private titleService = inject(TitleService);
    private formBuilder = inject(FormBuilder);
    configTypeControl = new FormControl<"openid_disc" | "manual">("openid_disc");
    configTypeTypes = {
        MANUAL: "manual",
        OPENID_DISCOVERY: "openid_disc"
    };

    submitted = false;
    isEdit = false;
    loading = true;
    saving = false;
    isInvalidSubmit = false;

    form = this.formBuilder.group({
        name: ["", [Validators.required, Validators.minLength(2), Validators.pattern(Constants.validators.name)]],
        authorizationURL: ["", [Validators.required, Validators.minLength(2)]],
        openIdConfigURL: ["", [Validators.required, Validators.minLength(2)]],
        tokenURL: ["", [Validators.required, Validators.minLength(2)]],
        clientID: ["", [Validators.required, Validators.minLength(2)]],
        clientSecret: ["", [Validators.required, Validators.minLength(2)]],
        userInfoURL: ["", [Validators.minLength(2)]],
        sso_only_preapproved: [false]
    });

    ssoNames$ = this.sks.ssos.pipe(
        map(ssos => {
            if (this.ssoId) ssos = ssos.filter(t => t.id !== this.ssoId);
            return ssos.map(t => t.name);
        })
    );

    constants = Constants;
    window = window;

    private prepForm() {
        // Set Title
        this.titleService.setTitle("SSO", this.action, this.sso);

        if (this.isEdit) {
            this.configTypeControl.setValue(this.sso.openIdConfigURL ? "openid_disc" : "manual");
            this.form.controls.authorizationURL.removeValidators(Validators.required);
            this.form.controls.tokenURL.removeValidators(Validators.required);
            this.form.controls.clientID.removeValidators(Validators.required);
            this.form.controls.clientSecret.removeValidators(Validators.required);

            this.form.patchValue({
                name: this.sso.name,
                openIdConfigURL: this.sso.openIdConfigURL,
                userInfoURL: this.sso.userInfoURL,
                sso_only_preapproved: !!this.sso.sso_only_preapproved
            });
        }
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.ssoId = params.id;
        this.action = params.action;
        this.sks.refreshSSOs();
        if (this.ssoId) {
            this.isEdit = true;
            this.sso = await firstValueFrom(this.sks.ssos.pipe(map(ssos => ssos.find(t => t.id === this.ssoId))));
        }

        this.configTypeControl.valueChanges.subscribe(value => {
            if (value === "openid_disc") {
                this.form.controls.openIdConfigURL.addValidators(Validators.required);
                this.form.controls.authorizationURL.removeValidators(Validators.required);
                this.form.controls.tokenURL.removeValidators(Validators.required);
            } else {
                this.form.controls.openIdConfigURL.clearValidators();
                this.form.controls.openIdConfigURL.updateValueAndValidity();
                if (!this.isEdit) {
                    this.form.controls.authorizationURL.addValidators(Validators.required);
                    this.form.controls.tokenURL.addValidators(Validators.required);
                }
            }
        });

        this.prepForm();
        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            ...this.form.value,
            authorizationURL: this.valueOrEmpty(this.form.value.authorizationURL),
            tokenURL: this.valueOrEmpty(this.form.value.tokenURL),
            clientID: this.valueOrEmpty(this.form.value.clientID),
            clientSecret: this.valueOrEmpty(this.form.value.clientSecret)
        };
        if (this.configTypeControl.value === "openid_disc") {
            model.authorizationURL = undefined;
            model.tokenURL = undefined;
            model.userInfoURL = undefined;
        } else {
            model.openIdConfigURL = undefined;
        }
        const result = this.isEdit ? await this.sks.updateSSO(this.sso, model) : await this.sks.addSSO(model);
        this.saving = false;
        if (result) await this.router.navigate([Constants.urls.accountManagement.sso]);
        this.isInvalidSubmit = true;
    }

    private valueOrEmpty(value?: string) {
        return !this.isEdit || value ? value : undefined;
    }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { UsersService } from "../users/users.service";
import { User } from "src/app/models/shared";
import { SettingsService, AccountSettings } from "src/app/services/settings.service";
import { TitleService } from "../../../services/title.service";
import { SSOsService } from "./ssos.service";

@Component({
    selector: "app-ssos",
    templateUrl: "./ssos.component.html"
})
export class SSOsComponent implements OnInit, OnDestroy {
    userAccount: User;
    activeTab = 1;
    freshSettings = true;
    savingSettings = false;
    accountSettings: AccountSettings;
    ssosLength = 0;

    private userAccountSubscription: Subscription;
    private ssosSubscription: Subscription;

    constructor(
        private us: UsersService,
        private ss: SettingsService,
        private titleService: TitleService,
        private ssoService: SSOsService
    ) {
        this.titleService.setTitle("SSO", "");
    }

    ngOnInit() {
        this.userAccountSubscription = this.us.user.subscribe(user => {
            this.userAccount = user;
            this.getSettings();
        });

        this.ssosSubscription = this.ssoService.ssos.subscribe(ssos => {
            this.ssosLength = ssos.length;

            if (!this.accountSettings?.allow_non_sso_login && !this.ssosLength) {
                this.accountSettings.allow_non_sso_login = 1;
                this.setSettings();
            }
        });
    }

    ngOnDestroy() {
        if (this.userAccountSubscription) this.userAccountSubscription.unsubscribe();
        if (this.ssosSubscription) this.ssosSubscription.unsubscribe();
    }

    getSettings() {
        this.ss.getSettings(this.userAccount.dns_prefix).then(settings => (this.accountSettings = settings));
    }

    get isNonSSOLoginCheckboxDisabled() {
        return !(this.userAccount.is_admin && this.ssosLength > 0);
    }

    async setSettings() {
        this.freshSettings = false;
        this.savingSettings = true;

        await this.ss.setSettings(this.userAccount.dns_prefix, this.accountSettings);

        this.savingSettings = false;
    }
}
